<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer app v-model="drawer" v-if="isInMainPage">
        <v-sheet class="pa-4">
          <v-img src="@/assets/aitech-logo.png" contain></v-img>
        </v-sheet>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item
            link
            v-for="menu in menus"
            :key="menu.title"
            :to="menu.to"
            color="primary"
          >
            <v-list-item-icon>
              <v-icon class="material-icons-outlined">{{ menu.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app flat color="transparent" v-if="isInMainPage">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <h2>{{ title }}</h2>
        <v-spacer></v-spacer>
        <UserMenu></UserMenu>
      </v-app-bar>
      <v-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu.vue";

export default {
  components: {
    UserMenu,
  },
  data: () => ({
    drawer: true,
    menus: [
      {
        icon: "handyman",
        title: "Tools",
        to: "/tools/list",
      },
      {
        icon: "mdi-bookshelf",
        title: "Library",
        to: "/library",
      },
    ],
    routesToExclude: ["Login", "Logout"],
  }),
  methods: {},
  computed: {
    title() {
      return this.$route.name;
    },
    isInMainPage() {
      return (
        this.$route.name && !this.routesToExclude.includes(this.$route.name)
      );
    },
  },
};
</script>