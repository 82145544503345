import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import { authGuard } from "@/auth/authGuard";

const routes = [
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/LogoutPage.vue"),
    meta: {
      excludeFromNav: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile"),
    beforeEnter: authGuard,
    meta: {
      title: "Profile",
    },
  },
  {
    path: "/",
    redirect: "/tools",
  },
  {
    path: "/library/:parent?",
    name: "Library",
    component: () => import("../views/library/index.vue"),
    beforeEnter: authGuard,
    meta: {
      title: "Library",
    },
  },
  {
    path: "/tools",
    component: () => import("../views/Tools/main.vue"),
    beforeEnter: authGuard,
    meta: {
      title: "Tools",
    },
    children: [
      {
        path: "",
        component: () => import("../views/Tools"),
        meta: {
          title: "Tools",
        },
        children: [
          {
            path: "",
            redirect: "list",
          },
          {
            path: "list",
            component: () => import("../views/Tools/List/ListPage"),
            meta: { title: "Tools" },
            children: [
              {
                path: "",
                name: "Tools",
                component: () => import("../views/Tools/List/ToolList"),
                beforeEnter: authGuard,
                meta: {
                  title: "Tools",
                },
              },
            ],
          },
          {
            path: "activities",
            name: "Activities",
            component: () => import("../views/Tools/Activities"),
            meta: { title: "Tools" },
          },
          {
            path: "users",
            name: "Users",
            component: () => import("../views/Tools/Users"),
            meta: { title: "Tools" },
          },
        ],
      },
      {
        path: "t/:toolKey",
        component: () => import("../views/Tools/ToolDataPage"),
        props: true,
        beforeEnter: authGuard,
        meta: {
          title: "Tools",
        },
        children: [
          {
            path: "",
            redirect: "info",
          },
          {
            path: "info",
            name: "Info",
            component: () => import("../views/Tools/ToolDataPage/ToolInfo"),
            meta: { title: "Tools" },
          },
          {
            path: "calibrations",
            name: "Calibrations",
            component: () =>
              import("../views/Tools/ToolDataPage/CalibrationLog"),
            meta: { title: "Tools" },
          },
          {
            path: "documents",
            name: "Documents",
            component: () =>
              import("../views/Tools/ToolDataPage/ToolDocuments"),
            meta: { title: "Tools" },
          },
          {
            path: "activities",
            name: "Activities",
            component: () =>
              import("../views/Tools/ToolDataPage/ToolActivities"),
            meta: { title: "Tools" },
          },
        ],
      },
      {
        path: "u/:userId",
        name: "User",
        props: true,
        component: () => import("../views/Tools/Users/ToolUser"),
        meta: { title: "Tools" },
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../views/Admin"),
    beforeEnter: authGuard,
    meta: {
      title: "Admin",
    },
    children: [
      {
        path: "",
        name: "Admin",
        component: () => import("../views/Admin/AdminHome.vue"),
        beforeEnter: authGuard,
        meta: {
          title: "Admin",
        },
      },
      {
        path: ":userId",
        name: "User Details",
        props: true,
        component: () => import("../views/Admin/UserDetails.vue"),
        beforeEnter: authGuard,
        meta: {
          title: "Admin",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Aitech Group`;
  next();
});

export default router;
