import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    toolTimestamp: null,
  },
  getters: {},
  mutations: {
    toolTimestamp(state, payload) {
      state.toolTimestamp = payload;
    },
  },
  actions: {},
  modules: {},
});
