import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00AFEF",
        secondary: "#022F40",
      },
    },
  },
  // icons: {
  //   iconfont: "md",
  // },
});
