<template>
  <div>
    <v-menu offset-y transition="slide-y-transition" rounded="lg">
      <template #activator="{ on }">
        <v-chip v-on="on">
          <v-avatar size="40" left v-on="on">
            <v-img :src="user.picture"></v-img>
          </v-avatar>
          {{ displayName }}
        </v-chip>
      </template>
      <v-list dense>
        <v-list-item
          v-for="menu in _menus"
          :key="menu.title"
          @click="goto(menu.to)"
          :disabled="menu.disabled"
        >
          <v-list-item-icon>
            <v-icon class="material-icons-outlined" :disabled="menu.disabled">{{
              menu.icon
            }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    isAdmin: false,
    menus: [
      {
        icon: "account_circle",
        title: "Profile",
        to: { name: "Profile" },
        admin: false,
        disabled: false,
      },
      {
        icon: "admin_panel_settings",
        title: "Admin",
        to: { name: "Admin" },
        admin: true,
        disabled: false,
      },
    ],
  }),
  mounted() {
    this.getTokenSilently();
  },
  methods: {
    async getTokenSilently() {
      const token = await this.$auth.getTokenSilently();
      const payload = token.split(".")[1];
      const claims = JSON.parse(atob(payload));

      if (claims.permissions?.includes("user:admin")) {
        this.isAdmin = true;
      }
    },
    goto(to) {
      this.$router.push(to);
    },
    async logout() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
  },
  computed: {
    user() {
      return this.$auth.user;
    },
    displayName() {
      return this.user.name || this.user.email;
    },
    _menus() {
      return this.menus.filter((menu) => {
        if (menu.admin) {
          return this.isAdmin;
        }
        return true;
      });
    },
  },
};
</script>

<style>
</style>